<template>
  <v-sheet
    class="ma-5"
    color="transparent"
  >
    <p v-html="siteProfile.comment_secondary"></p>
  </v-sheet>
</template>

<script>
export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    siteProfile: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },

  data() {
    return {
    };
  },

  created() {
  },

  methods: {
  }
};
</script>

<style scoped>
.v-sheet {
  max-height: 270px;
  overflow-y: scroll;
}
</style>
