<template>
  <v-row no-gutters
    class="justify-center"
  >
    <v-col
      :cols="videos.length == 1 ? 12 : 6"
      class="video-col pa-0 d-flex"
      :class="videos.length == 1 ? 'one' : 'multiple'"
      v-for="video in videos"
      :key="video.video_id"
    >
      <video
        class="flex-grow-1"
        controls
        :poster="video.poster_url"
        :src="video.video_url"
      ></video>
    </v-col>
  </v-row>
</template>

<script>
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    castData: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },

  data() {
    return {
      videos: [],
      apiTool: new ApiTool(this.siteApi, this.siteData),
    }
  },

  created() {
    this.getCastVideos()
  },

  methods: {
    getCastVideos() {
      const apiPartial = 'cast/' + this.castData.cast_id + '/video/'

      this.apiTool.getRequest(apiPartial).then( videos => {
        this.videos = videos
      })
      .catch( error => AlertApiError(error) )
    },
  }
};
</script>

<style scoped>
video {
  max-width: 100%;
}
.video-col:nth-child(even) {
  text-align: right;
}
</style>
