<template>
  <v-row no-gutters
    class="pa-4"
  >
    <v-col cols="6"
      class="pa-1 d-flex"
      v-for="option in options"
      :key="option.option_id"
    >
      <v-chip
        class="option py-5 px-4 flex-grow-1 font-weight-bold"
        label
        :outlined="option.available ? false : true"
        :color="option.available ? 'accent' : 'primary'"
      >
        {{ option.option_name }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    castData: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },

  data() {
    return {
      options: [],
      apiTool: new ApiTool(this.siteApi, this.siteData),
    };
  },

  created() {
    this.getAndSetOptions()
  },

  methods: {
    getAndSetOptions() {
      //店舗オプション → キャストオプションを取得
      this.apiTool.getRequest('option/').then( shopOpts => {
        if (!shopOpts || !shopOpts.length) return

        this.apiTool.getRequest('cast/' + this.castData.cast_id + '/option/').then( castOpts => {
          if (!castOpts || !castOpts.length) {
            this.options = shopOpts
            return
          }

          //可能オプションにフラグ付与
          castOpts.forEach( opt => {
            const index = shopOpts.findIndex( shopOpt => shopOpt.option_id === opt.option_id )
            shopOpts[index].available = true
          });

          this.options = shopOpts
        })
        .catch( error => AlertApiError(error) )
      })
      .catch( error => AlertApiError(error) )
    },
  }
};
</script>

<style scoped>
</style>
