<template>
  <v-row no-gutters>
    <!-- Carousel -->
    <v-col cols="12">
      <v-sheet class="hero elevation-2">
        <v-carousel
          cycle
          hide-delimiters
          show-arrows-on-hover
          interval="4000"
          height="auto"
          max-height="500"
          v-model="imageCtl"
        >
          <v-carousel-item
            v-for="image in images"
            :key="image.image_id"
          >
            <v-img
              contain
              :src="image.image_url"
              :lazy-src="lazyload_img"
              height="inherit"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0 justify-center"
                  align="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="accent"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </v-sheet>
    </v-col>

    <!-- Image list -->
    <v-col cols="12">
      <v-sheet color="transparent">
        <v-item-group mandatory>
          <v-row no-gutters
            class="flex pt-2"
          >
            <v-col cols="2"
              class="pa-0 pr-1"
              v-for="(image, index) in images"
              :key="image.image_id"
            >
              <v-item v-slot="{ active, toggle }">
                <v-img
                  :class="{'active': active}"
                  min-height="75"
                  :src="image.image_url"
                  :lazy-src="lazyload_img"
                  @click="switchImage(index, toggle)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0 justify-center"
                      align="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="accent"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { LAYZLOAD_IMG } from '@/literals.js';

export default {
  components: {
  },

  props: {
    images: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      imageCtl: 0,
      lazyload_img: LAYZLOAD_IMG,
    };
  },

  computed: {
  },

  created() {
  },

  methods: {
    switchImage(index, fnToggle) {
      fnToggle()
      this.imageCtl = index
    }
  }
};
</script>

<style scoped>
>>> .v-responsive__content {
  height: 100%;
}
</style>
