<template>
  <v-sheet
    class="flex-grow-1"
    color="transparent"
  >
    <v-list
      class="py-3 px-5"
      color="secondary"
    >
      <v-list-item
        class="schedule-day pa-0"
        v-for="day in weekSchedule"
        :key="day.shift_date"
      >
        <v-list-item-content class="py-0">
          {{ jpDate(day.shift_date) }}
        </v-list-item-content>
        <v-list-item-content class="py-0">
          {{ getShiftStatus(day) }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn class="btn-booking"
            :disabled="!day.cast_id"
            small
            depressed
            color="accent"
            @click="bookingClicked(day)"
          >予約
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <!-- 予約フォーム -->
    <v-bottom-sheet
      v-model="openBooking"
      inset
      persistent
      overlay-opacity="0.5"
    >
      <form-booking
        :siteApi="siteApi"
        :siteData="siteData"
        :setting="setting"
        :shiftInfo="shiftInfo"
        @cancel="openBooking = false"
      ></form-booking>
    </v-bottom-sheet>
  </v-sheet>
</template>

<script>
import moment from 'moment'
import 'moment/locale/ja'
import { BizHour, ApiTool, AlertApiError } from '@/module.js';
import FormCastBooking from '@/components/_FormCastBooking.vue'

export default {
  components: {
    'form-booking': FormCastBooking,
  },

  props: {
    siteApi: {
      type: String,
      required: true,
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    castData: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },

  data() {
    return {
      shifts: [],
      shiftInfo: {},
      weekSchedule: [],
      displayShiftDays: 7,
      openBooking: false,
      bizHour: new BizHour(this.siteData),
      apiTool: new ApiTool(this.siteApi, this.siteData),
    };
  },

  computed: {
    todayShift() {
      const today = moment(this.bizHour.getBizOpening(new Date())).format('YYYYMMDD')
      return this.shifts.find( shift => moment(shift.shift_date).format('YYYYMMDD') === today )
    },
    extractTime() {
      return datime => moment(datime).format('HH:mm')
    },
    jpDate() {
      return date => {
        const bizToday = moment(this.bizHour.getBizOpening(new Date())).format('YYYYMMDD')
        if (moment(date).format('YYYYMMDD') === bizToday) {
          return '本日'
        } else {
          return moment(date).format('M/D (dd)')
        }
      }
    },
    getShiftStatus() {
      return shiftData => {
        if (shiftData.cast_id !== undefined) {
          return this.extractTime(shiftData.start_at) + "〜" + this.extractTime(shiftData.end_at)
        } else {
          return '未定'
        }
      }
    }
  },

  created() {
    this.getCastShifts()
  },

  methods: {
    getCastShifts() {
      const apiPartial = 'cast/' + this.castData.cast_id + '/shift/'
      const fromDate = this.bizHour.getBizOpening(new Date())
      const toDate = this.bizHour.getBizClosing(moment(new Date()).add(this.displayShiftDays, 'd'))
      const query = {fromDate: fromDate, toDate: toDate}

      this.apiTool.getRequest(apiPartial, query).then( shifts => {
        //日数分ダミーのシフトデータを用意
        const dummyDate = moment(fromDate).clone()
        for (let i = 0; i < this.displayShiftDays; i++) {
          this.weekSchedule.push({
            shift_date: dummyDate.format('YYYY-MM-DD'),
            start_at: dummyDate.format('YYYY-MM-DD HH:mm'),
            end_at: dummyDate.format('YYYY-MM-DD HH:mm'),
          })
          dummyDate.add(1, 'd')
        }

        if (!shifts) return
        this.shifts = shifts

        //取得した出勤データでダミーシフトを上書き
        shifts.map( row => {
          const index = this.weekSchedule.findIndex( day => {
            return moment(day.shift_date).format('YYYYMMDD') === moment(row.shift_date).format('YYYYMMDD')
          })
          if (index > -1) {
            this.weekSchedule[index] = row
          }
        })
      })
      .catch( error => AlertApiError(error) )
    },

    //予約ボタンクリック：フォームオープン
    bookingClicked(shiftInfo) {
      this.openBooking = true
      shiftInfo.cast_name = this.castData.name
      this.shiftInfo = shiftInfo
    },
  }
};
</script>

<style scoped>
.schedule-day {
  height: 45px;
}
</style>
