import { render, staticRenderFns } from "./CommentPrimary.vue?vue&type=template&id=2d00dc61&scoped=true&"
import script from "./CommentPrimary.vue?vue&type=script&lang=js&"
export * from "./CommentPrimary.vue?vue&type=script&lang=js&"
import style0 from "./CommentPrimary.vue?vue&type=style&index=0&id=2d00dc61&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d00dc61",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VSheet})
