<template>
  <v-container :fluid="$vuetify.breakpoint.smAndDown">
    <!-- 左列 -->
    <v-row>
      <v-col cols="5">
        <!-- パネル -->
        <hero-slider
          v-if="images"
          :images="images"
        ></hero-slider>

        <!-- 左列動的コンポーネント -->
        <v-card
          class="my-7"
          color="transparent"
          v-for="component in componentsL"
          :key="component.component_name"
        >
          <v-card-title class="mb-1 primary font-weight-bold"
            v-if="component.display_name"
          >
            <h3>{{ component.display_name }}</h3>
          </v-card-title>

          <v-card-actions class="pa-0" :class="component.bg_color">
            <component :is="componentName(component.component_name)"
              :siteApi="siteApi"
              :siteData="siteData"
              :setting="setting"
              :castData="needsCastsData(component.component_name) && cast"
              :siteProfile="needsSiteProfile(component.component_name) && profile"
              :id="componentId(component.component_name)"
            >
            </component>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- 右列 -->
      <v-col cols="7">
        <!-- プロフィール枠 -->
        <v-card
          class="profile mb-7"
          color="transparent"
          v-if="profile"
        >
          <!-- キャッチコピー -->
          <h3 class="py-3 mb-1 pl-3 primary font-weight-bold">
            {{ profile.catch_copy }}
          </h3>

          <v-sheet class="px-4 py-5" color="secondary">
            <div class="d-flex align-center">
              <!-- 名前 -->
              <v-card-title class="mr-4 pa-0 font-weight-bold">
                {{ cast.name }} ({{ cast.age }})
              </v-card-title>
              <!-- <v-spacer></v-spacer>
              <v-card
                class=""
                height="30"
                flat
                color="accent"
                v-if="todayShift"
              >
                <v-card-text class="pa-0 px-2 text-overline font-weight-bold">
                  本日 {{ extractTime(todayShift.start_at) }}〜{{ extractTime(todayShift.end_at) }}
                </v-card-text>
              </v-card> -->
            </div>

            <!-- 3サイズ -->
            <h4 class="mt-2 pa-0">
              T{{ cast.height }}cm B{{ cast.bust }}({{ cast.cup }}) W{{ cast.waist }} H{{ cast.hip }}
            </h4>

            <v-card-text class="mt-5 pa-0">
              <!-- タグクラウド -->
              <v-list class="pa-0"
                color="transparent"
                v-if="tags"
              >
                <v-chip
                  class="mr-2 px-4 font-weight-bold"
                  color="accent"
                  v-for="tag in tags"
                  :key="tag.tag_id"
                >
                  {{ tag.tag_name }}
                </v-chip>
              </v-list>
              <!-- SNS -->
              <v-chip-group class="pa-0 mt-3"
                color="transparent"
                v-if="snsArray"
              >
                <v-chip
                  class="ma-0 mr-2 px-3 font-weight-bold"
                  color="accent"
                  label outlined
                  link
                  :href="sns.sns_account_url"
                  target="_blank"
                  v-for="sns in snsArray"
                  :key="sns.sns_name"
                >
                  <v-icon
                    class="mr-1"
                  >
                    mdi-{{ sns.icon_name }}
                  </v-icon>
                  {{ sns.sns_name }}
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-sheet>
        </v-card>

        <!-- 右列動的コンポーネント -->
        <v-card
          class="my-7"
          :flat="component.bg_color==='transparent'"
          color="transparent"
          v-for="component in componentsR"
          :key="component.component_name"
        >
          <v-card-title class="mb-1 primary font-weight-bold"
            v-if="component.display_name"
          >
            <h3>{{ component.display_name }}</h3>
          </v-card-title>

          <v-card-actions class="pa-0" :class="component.bg_color">
            <component :is="componentName(component.component_name)"
              :siteApi="siteApi"
              :siteData="siteData"
              :setting="setting"
              :castData="needsCastsData(component.component_name) && cast"
              :siteProfile="needsSiteProfile(component.component_name) && profile"
              :id="componentId(component.component_name)"
            >
            </component>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- 下段動的コンポーネント -->
    <v-row>
      <v-card
        class="mb-7"
        width="100vw"
        color="transparent"
        v-for="component in componentsB"
        :key="component.component_name"
      >
        <v-card-title class="mb-1 primary font-weight-bold"
          v-if="component.display_name"
        >
          <h3>{{ component.display_name }}</h3>
        </v-card-title>

        <v-card-actions class="pa-0" :class="component.bg_color">
          <component :is="componentName(component.component_name)"
            :siteApi="siteApi"
            :siteData="siteData"
            :setting="setting"
            :castData="needsCastsData(component.component_name) && cast"
            :siteProfile="needsSiteProfile(component.component_name) && profile"
            :id="componentId(component.component_name)"
          >
          </component>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************** Script *************** -->
<!-- ************************************* -->
<script>
import { MESSAGE } from '@/literals.js';
import { ApiTool, AlertApiError } from '@/module.js';
import CastHeroSlider from '@/components/_CastHeroSlider.vue'
import CommentPrimary from '@/components/CommentPrimary.vue'
import CommentSecondary from '@/components/CommentSecondary.vue'
import CastOption from '@/components/_CastOption.vue'
import CastShift from '@/components/_CastShift.vue'
import CastVideo from '@/components/_CastVideo.vue'
import ImageBanner from '@/components/_ImageBanner.vue'
import WidgetTag from '@/components/_WidgetTag.vue'

//***************************************************
//
// Component
//
//***************************************************
export default {
  components: {
    'hero-slider': CastHeroSlider,
    'cast-comment1': CommentPrimary,
    'cast-comment2': CommentSecondary,
    'cast-option': CastOption,
    'cast-shift': CastShift,
    'cast-video': CastVideo,
    'image-banner': ImageBanner,
    'widget-tag': WidgetTag,
  },

  props: {
    siteApi: {
      type: String,
      required: true
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    menus: {
      type: Array,
      required: true,
    },
    // From vue router's params
    // castData: {
    //   type: Object,
    //   required: true,
    //   default: () => ({})
    // },
  },

  //***************************************************
  // Data
  //***************************************************
  data() {
    return {
      components: [],
      orderDelimitar: '_',
      cast: {},
      castId: '',
      profile: {},
      images: [],
      tags: [],
      snsArray: [],
      apiTool: new ApiTool(this.siteApi, this.siteData),
    }
  },

  //***************************************************
  // Computed
  //***************************************************
  computed: {
    componentsL() {
      return this.components.filter( comp => comp.placement === 'left' )
    },
    componentsR() {
      return this.components.filter( comp => comp.placement === 'right' )
    },
    componentsB() {
      return this.components.filter( comp => comp.placement === 'bottom' )
    },
    componentName() {
      return name => name.split(this.orderDelimitar)[0]
    },
    componentId() {
      return name => {
        const i = name.indexOf(this.orderDelimitar)
        if (i > -1) {
          const ary = name.split(this.orderDelimitar)
          return parseInt(ary[ary.length - 1])
        } else {
          return 1
        }
      }
    },
    needsCastsData() {
      return name => name.indexOf('cast') > -1 ? true : false
    },
    needsSiteProfile() {
      return name => name.indexOf('comment') > -1 ? true : false
    },
  },

  //***************************************************
  // Life cycle
  //***************************************************
  created() {
      this.castId = this.$route.params.castId

      this.initData()
      .catch( error => AlertApiError(error) )
  },

  //***************************************************
  // Methods
  //***************************************************
  methods: {
    async initData() {
      await this.getACastData()

      //該当データ無しなら一覧へ
      if (!this.cast.cast_id) {
        alert(MESSAGE.requestNotFound)
        this.$router.replace({name: 'cast'});
        return
      }

      await this.getComponentList()

      await Promise.all([
        this.getCastImages(),
        this.getCastTags(),
        this.getCastSns(),
        this.getCastProfile(),
      ])
    },

    // API req: キャストデータ
    getACastData() {
      const apiPartial = 'cast/' + this.castId + '/'
      return this.apiTool.getRequest(apiPartial).then( cast => {
        if (!cast) return

        this.cast = cast
      })
    },

    // API req: コンポーネントリスト
    getComponentList() {
      return this.apiTool.getRequest('profile/component/').then( components => {
        this.components = components
      })
    },

    // API req: Site profile
    getCastProfile() {
      const apiPartial = 'cast/' + this.castId + '/site-profile/'
      this.apiTool.getRequest(apiPartial).then( profile => {
        this.profile = profile
      })
    },

    // API req: Images
    getCastImages() {
      const apiPartial = 'cast/' + this.castId + '/image/'
      return this.apiTool.getRequest(apiPartial).then( images => {
        this.images = images
      })
    },

    // API req: Tags
    getCastTags() {
      const apiPartial = 'cast/' + this.castId + '/tag/'
      return this.apiTool.getRequest(apiPartial).then( tags => {
        this.tags = tags
      })
    },

    // API req: Sns
    getCastSns() {
      const apiPartial = 'cast/' + this.castId + '/sns/'
      return this.apiTool.getRequest(apiPartial).then( sns => {
        this.snsArray = sns
      })
    },
  }
};
</script>

<style scoped>
</style>
